import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth.service';
import { SharedService } from '../../../shared/services/shared.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DeploymentsService } from '../../deployments.service';
import { map, switchMap, tap, take, takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DeploymentConfiguration, DeploymentService } from 'api';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss'],
})
export class ConfigurationsComponent implements OnInit {
  private refreshToken$ = new BehaviorSubject<void>(undefined);

  public deployment$: Observable<any>;

  public tenantName: string;

  private sub = new Subscription();

  constructor(
    private deploymentsService: DeploymentsService,
    private deploymentService: DeploymentService,
    private authService: AuthService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.tenantName = this.authService.getCurrentTenantData();

    this.deployment$ = this.refreshToken$.pipe(
      switchMap(() => this.deploymentsService.deployment$(this.route.snapshot.paramMap.get('deploymentId'))),
      map(deployment => {
        const noApiKeyDeployment = { ...(deployment as any) };
        delete noApiKeyDeployment.configuration?.FHIRAPIKey;
        return noApiKeyDeployment;
      }),
      takeWhile(deployment => !deployment?.deploymentid, true),
    );
  }

  ///
  ///id: 'role',
  ///id: 'inputLocation',
  ///id: 'targetType',
  ///id: 'apikey',
  ///id: 'endpoint',
  ///id: 'amazonHealthLakeId',
  ///id: 'targetLocation',
  ///id: 'enabledArchiving',
  ///id: 'archiving',

  createInterfaceConfiguration(formData: any): void {
    const configuration = this.createConfiguration(formData);
    const serviceCall = this.deploymentService.updateDeployment(
      this.authService.getTenantId(),
      this.route.snapshot.paramMap.get('deploymentId'),
      { configuration },
    );
    this.sub.add(
      serviceCall.pipe(tap(() => this.refreshToken$.next())).subscribe(
        () => {
          this.sharedService.showSuccess('Successfully configured interface.');
          this.router.navigate(['/deployments']);
        },
        () => {
          this.sharedService.showAlert('Something went wrong. Please, try again later.');
        },
      ),
    );
  }

  createTrigger(formData: any): void {
    const configuration = this.createConfiguration(formData);
    const serviceCall = this.deploymentService.createTrigger(
      this.authService.getTenantId(),
      this.route.snapshot.paramMap.get('deploymentId'),
      { configuration },
    );
    this.sub.add(
      serviceCall.pipe(tap(() => this.refreshToken$.next())).subscribe(
        () => {
          this.sharedService.showSuccess('Successfully created trigger.');
        },
        () => {
          this.sharedService.showAlert('Something went wrong. Please, try again later.');
        },
      ),
    );
  }

  createConfiguration(formData: any): DeploymentConfiguration {
    const configuration: any = {
      CustomerSrcS3Bucket: formData.CustomerSrcS3Bucket,
      CustomerSrcS3Prefix: formData.CustomerSrcS3Prefix,
      ErrorBehavior: formData.ErrorBehavior,
      Archive: formData.Archive,
    };
    if (formData.targetType.value == 'fhir_server') {
      configuration.FHIRAPIKey = formData.FHIRAPIKey;
      configuration.FHIRServer = formData.FHIRServer;
      configuration.BundleMode = formData.BundleMode.value;
    }
    if (formData.targetType.value == 's3') {
      configuration.CustomerDestS3Bucket = formData.CustomerDestS3Bucket;
      configuration.CustomerDestS3Prefix = formData.CustomerDestS3Prefix;
      configuration.BundleMode = formData.BundleMode.value;
    }
    if (formData.targetType.value == 'healthlake') {
      configuration.HealthLakeDatastoreID = formData.HealthLakeDatastoreID;
      configuration.HealthLakeRegion = formData.HealthLakeRegion;
    }

    return configuration;
  }

  onTestFields(form): boolean {
    console.log(form);
    alert('LGTM');
    // TODO: formModel.inputLocation and other parameters should be tested by regex in future
    return true;
  }
}
